<template>
  <vs-card>
    <h4 class="mb-6 ml-5 mt-2">Timing of treatment for {{ dataToEdit.name }}</h4>
    <form autocomplete="off" @submit.prevent>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full ml-5">
            <label class="label">Timig of Treatment</label>
            <vs-textarea
              :success="
                !errors.first('basic.treatmentTiming') && dataToSave.treatmentTiming != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.treatmentTiming') ? true : false"
              v-validate="'required'"
              name="treatmentTiming"
              data-vv-as="Treatment Timing"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.treatmentTiming')"
              v-model="dataToSave.treatmentTiming"
              style="margin-bottom: 5px !important"
            />
            <span class="text-danger text-sm">
              {{ errors.first("basic.treatmentTiming") }}
            </span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full ml-5">
            <vs-button size="normal" icon-pack="feather" class="mr-2" @click="submit"
              >Submit</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </form>
  </vs-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TreatmentTypesEdit",
  data() {
    return {
      dataToEdit: undefined,
      dataToSave: {
        treatmentTiming: "",
      },
      routeType: undefined
    };
  },
  methods: {
    ...mapActions("treatmentTypes", ["treatmentTypesById", "saveTreatmentTiming"]),
    fetchById(id) {
      this.$vs.loading();
      this.treatmentTypesById({ id })
        .then((res) => {
          this.dataToEdit = res.data.data;
          this.dataToSave.treatmentTiming = this.dataToEdit.treatmentTiming;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    async submit() {
      const id = this.$route.params.id;
      let isValid = await this.$validator.validateAll("basic");
      if (isValid) {
        this.$vs.loading();
        this.saveTreatmentTiming({ id, data: this.dataToSave })
          .then((res) => {
            this.$vs.notify({
              title: "Success",
              text: "Treatment timing saved successfully",
              color: "success",
            });
            this.$router.push(`/${this.routeType}/treatment-types`);
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger",
            });
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {
    if (this.$store.state.AppActiveUser.userType === "superAdmin")
      this.routeType = "super-admin";
    else this.routeType = "admin";

    const id = this.$route.params.id;
    this.fetchById(id);
  },
};
</script>
